.container-datePiker {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 28.8em; }

.datePiker-input {
  width: 100%;
  height: 35px;
  padding: 0.375rem 0.75rem;
  cursor: pointer; }

.datePiker-img {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 18px;
  transform: translateY(-50%); }

.cnpj-picker-wrapper {
  width: 350px;
  border-color: #767676;
  margin-left: 35px; }

.cnpj-text-b {
  margin-bottom: 10px;
  display: block;
  margin-left: 35px; }

.period-text-b {
  margin-bottom: 10px;
  display: block; }

.btn-report-perfomance {
  margin-top: 29px;
  margin-left: 60px; }
